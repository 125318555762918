<template>
    <div ref="emblaRef" class="embla overflow-hidden h-full flex bg-lolo-600">
        <div class="embla__container flex flex-1">
            <div v-for="(image, i) in images" :key="image.src" class="embla__slide overflow-hidden min-w-0 flex-none w-full">
                <AirImg
                    :loading="image.preload ? 'eager' : 'lazy'"
                    :preload="image.preload"
                    :fetchpriority="image.preload ? 'high' : 'low'"
                    class="flex-1 bg-lolo-400/10 size-full block will-change-transform"
                    :class="slideSelect === i ? 'animate-slide-zoom' : 'transition-all duration-800 scale-[1.16] opacity-0'"
                    image-class="lg:max-lg:object-bottom  object-cover"
                    :src="image.src"
                    alt="'Aire & Montagne' image d'accueil"
                    :quality="70"
                    :width="412"
                    :height="494"
                    :gravity="image.gravity?.default || [0.3, 0]"
                    :dpr="[1, 1.8]"
                    :variant="[
                        { breakpoint: 'sm', width: 768, gravity: image.gravity?.sm },
                        { breakpoint: 'md', width: 1024, gravity: image.gravity?.md || [0.1, 0.4] },
                        { breakpoint: 'lg', width: 1264, height: 1040, dpr: [1], gravity: image.gravity?.lg || [0, 0.6], quality: 80 },
                        { breakpoint: 'xl', width: 1520, height: 1040, dpr: [1], gravity: image.gravity?.xl || [0, 0.5], quality: 90 },
                        { breakpoint: 'xxl', width: 1920, height: 800, dpr: [1], gravity: image.gravity?.xxl || [0, 0.61], quality: 90 }
                    ]"
                />
            </div>
        </div>
        <div class="absolute w-full bottom-3 flex justify-center">
            <div class="my-container justify-center flex md:justify-end">
                <div class="flex w-min gap-3 items-center z-10 bg-white/10 backdrop-blur-xl rounded-full">
                    <div
                        v-if="$viewport.isGreaterThan('sm')"
                        class="size-9 text-white hover:text-primary transition-colors flex items-center justify-center rounded-full cursor-pointer"
                        @click="prevSlide"
                    >
                        <UIcon name="si:chevron-left-alt-line" :size="20" />
                    </div>
                    <div class="flex gap-2 p-3 md:p-0">
                        <div
                            v-for="(_image, i) in images"
                            :key="i"
                            class="h-2 w-2 rounded-full transition-all"
                            :class="i === slideSelect ? 'bg-white' : 'bg-white/50'"
                        />
                    </div>
                    <div
                        v-if="$viewport.isGreaterThan('sm')"
                        class="size-9 text-white hover:text-primary transition-colors flex items-center justify-center rounded-full cursor-pointer"
                        @click="nextSlide"
                    >
                        <UIcon name="si:chevron-right-alt-line" :size="20" />
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script lang="ts" setup>
import type { EmblaCarouselType } from 'embla-carousel';
import emblaCarouselVue from 'embla-carousel-vue';
import fade from 'embla-carousel-fade';
type Gravity = 'auto' | 'top' | 'right' | 'bottom' | 'left' | [number, number];
export type ImageSlider = {
    src: string;
    preload?: boolean;
    gravity?: { default?: Gravity; sm?: Gravity; md?: Gravity; lg?: Gravity; xl?: Gravity; xxl?: Gravity };
};
defineProps<{
    images: ImageSlider[];
}>();

const emit = defineEmits<{
    (e: 'indexChange', value: number): void;
}>();

const [emblaRef, emblaApi] = emblaCarouselVue({ loop: true, duration: 30 }, [fade({})]);
const slideSelect = ref(0);
let interval: ReturnType<typeof setInterval> | null = null;

function nextSlide() {
    emblaApi.value?.scrollNext();
}

function prevSlide() {
    emblaApi.value?.scrollPrev();
}

function changeIndex(emblaApi: EmblaCarouselType): void {
    if (interval) clearInterval(interval);
    startInterval();
    slideSelect.value = emblaApi.selectedScrollSnap();
    emit('indexChange', slideSelect.value);
}

function startInterval() {
    if (interval) clearInterval(interval);
    interval = setInterval(() => emblaApi.value?.scrollNext(), 5000);
}

onMounted(() => {
    if (!emblaApi.value) return;
    startInterval();
    emblaApi.value.on('select', changeIndex);
});
</script>
