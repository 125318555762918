<template>
    <div ref="emblaRef" class="embla overflow-hidden h-full flex w-full">
        <div class="embla__container flex flex-1 -ml-4 w-full">
            <slot />
        </div>
    </div>
    <div class="flex justify-end w-full mt-4">
        <div v-if="displayNav" class="flex gap-2">
            <UButton
                size="xl"
                color="lolo"
                :disabled="index === 0"
                variant="soft"
                class="rounded-full cursor-pointer"
                icon="ph:caret-left"
                aria-label="Preview slide"
                @click="prevSlide"
            />
            <UButton
                size="xl"
                color="lolo"
                :disabled="index + 1 === total"
                variant="soft"
                class="rounded-full cursor-pointer"
                icon="ph:caret-right"
                aria-label="Next slide"
                @click="nextSlide"
            />
        </div>
    </div>
</template>

<script lang="ts" setup>
import type { EmblaCarouselType } from 'embla-carousel';
import emblaCarouselVue from 'embla-carousel-vue';
const { $viewport } = useNuxtApp();

const [emblaRef, emblaApi] = emblaCarouselVue({
    loop: false,
    duration: 20,
    watchDrag: () => $viewport.isLessOrEquals('sm'),
    slidesToScroll: 'auto'
});

const index = ref(0);
const total = ref(0);
const displayNav = ref(true);

function nextSlide() {
    emblaApi.value?.scrollNext();
}

function prevSlide() {
    emblaApi.value?.scrollPrev();
}

function getTotal(api: EmblaCarouselType) {
    total.value = api.slideNodes().length || 0;
    displayNav.value = api.scrollSnapList().length > 1;
}

onMounted(() => {
    emblaApi.value?.on('select', api => {
        index.value = api.selectedScrollSnap() || 0;
    });
    emblaApi.value?.on('slidesInView', getTotal);
    emblaApi.value?.on('resize', api => {
        displayNav.value = api.scrollSnapList().length > 1;
    });
});
</script>
