<template>
    <SlidesActivities>
        <div v-for="activity in activities" :key="activity.id" class="pl-4 flex flex-none w-85/100 md:w-full max-w-90 min-w-0">
            <CardAct :activity="activity" />
        </div>
    </SlidesActivities>
</template>

<script lang="ts" setup>
import { useActivitiesStore } from '~/store/activities';
import { CardAct } from './components';

const activitiesStore = useActivitiesStore();
const activities = computed(() => activitiesStore.activities.filter(a => a.published));
await activitiesStore.awaitActivities();
</script>

<style></style>
